html,
body,
body > div:first-child,
div#__next,
div#__next > div:not(.Toastify) {
    height: 100vh;
    width: 100vw;
    margin: 0;
    padding: 0;
}

a {
    color: inherit;
    text-decoration: none;
}

:root {
    --toastify-z-index: 99999999 !important;
}

.fa-inverse {
    color: inherit !important;
}

.svg-inline--fa {
    height: inherit !important;
}

/* width */
::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #00000026;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888888e0;
    border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

* {
    scrollbar-color: #888888e0 #ffffff00;
    scrollbar-width: thin;
    /*   overflow-y: hidden;*/
    user-select: none;
}

.adv-icon {
    color: var(--adv-icon-primary-color);

    --fa-inverse: var(--adv-icon-primary-color);
    --fa-border-color: var(--adv-icon-primary-color);
    --fa-primary-color: var(--adv-icon-primary-color);
    --fa-secondary-color: var(--adv-icon-secondary-color);
    --fa-secondary-opacity: 1;
}

*.ms-Button--icon-Extra {
    padding: 0;
}

*.ms-Button:disabled > span > i > div > .adv-icon {
    color: var(--adv-icon-primary-color--disabled);

    --fa-inverse: var(--adv-icon-primary-color--disabled);
    --fa-border-color: var(--adv-icon-primary-color--disabled);
    --fa-primary-color: var(--adv-icon-primary-color--disabled);
    --fa-secondary-color: var(--adv-icon-secondary-color--disabled);
    --fa-secondary-opacity: 1;
}

*.ms-Button:hover:not(:disabled) > span > i > div > .adv-icon {
    color: var(--adv-icon-primary-color--hover);

    --fa-inverse: var(--adv-icon-primary-color--hover);
    --fa-border-color: var(--adv-icon-primary-color--hover);
    --fa-primary-color: var(--adv-icon-primary-color--hover);
    --fa-secondary-color: var(--adv-icon-secondary-color--hover);
    --fa-secondary-opacity: 1;
}

/* overwrite the overflow of the contentWrapper of the ms details list, because we use a webkit specific overload */
.ms-DetailsList-contentWrapper:hover {
    overflow-y: overlay;
}

.ms-DetailsList-contentWrapper:not(:hover)::-webkit-scrollbar {
    width: 0px;
}

.ms-DetailsRow-cell {
    font-size: 14px;
}

.fui-DataGridSelectionCell__radioIndicator {
    display: none !important;
}

.adv-SingleLine {
    overflow-wrap: anywhere;
    display: -webkit-box !important;
    line-clamp: 2;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: nowrap;
    overflow: hidden;
}

.adv-fui-TableHeaderCell {
    overflow-wrap: anywhere;
    display: -webkit-box !important;
    line-clamp: 2;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.fui-FluentProvider {
    z-index: 99 !important;
}

.adv-fui-BreadcrumbItem {
    overflow-wrap: anywhere;
    display: -webkit-box !important;
    line-clamp: 2;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-wrap: balance !important;
}

.adv-fui-MenuItem {
    background-color: transparent !important;
}
.adv-fui-MenuItem:hover {
    background-color: transparent !important;
}

.ms-Panel-content {
    padding-left: 8px;
    padding-right: 8px;
    padding-bottom: 8px;
}
